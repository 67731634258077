/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('./node_modules/@pella/sash-framework/dist/styles/index.css');

import wrapWithProvider from './wrap-with-provider';
export const wrapRootElement = wrapWithProvider;

// pella.com route updates
export const onRouteUpdate = ({ location }) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'gatsby-route-change',
	});

	const startsWith = /^[a-zA-Z]/i;
	const specialCharacters = /^[a-zA-Z0-9-_ ]*$/;
	if (location.hash && location.hash !== '' && startsWith.test(location.hash.substring(1)) && specialCharacters.test(location.hash.substring(1))) {
		window.scrollTo(0, 0);

		// If there is an anchor, scroll to that anchor
		setTimeout(() => {
			if (document.querySelector(`${location.hash}`)) {
				document.querySelector(`${location.hash}`).scrollIntoView();
			}
		}, 0);
	} else {
		// Otherwise scroll to the top of the page
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
	}
};
